<template>
  <section>
    <img
      v-lazy="'/img/soy-medico/amii-waves-login.svg'"
      alt="AMII olas decorativas"
      width="100%"
    >
    <div
      v-if="medicoLogin"
      class="login-section"
    >
      <header class="h1-responsive">
        ¿Ya estás registrado?
      </header>
      <a
        href="https://medico.amii.app/"
        target="_blank"
        rel="noopener noreferrer"
        class="col-auto login-btn"
      >
        <mdb-btn
          color="success-base"
          icon="sign-in-alt"
          class="px-2 px-sm-3 px-lg-4"
        >
          Inicia sesión como médico
        </mdb-btn>
      </a>
    </div>
    <footer>
      <div class="container">
        <div class="row mx-0 align-items-center justify-content-center py-5">
          <div class="col-12 col-sm col-lg-auto pr-lg-5">
            <header class="h4-responsive">
              ¿Tienes alguna pregunta?
            </header>
            <p class="lead font-italic mb-0">
              Escríbenos a través de nuestras redes sociales o el formulario de
              contacto
            </p>
          </div>
          <div class="col-auto pt-3">
            <router-link
              v-slot="{ navigate }"
              :to="{name: 'contactanos'}"
              custom
              class="cursor-pointer"
            >
              <img
                v-lazy="'/img/soy-medico/amii-icono-blanco.svg'"
                role="link"
                alt="AMII icono enlace"
                height="45px"
                width="45px"
                @click="navigate"
              >
            </router-link>
            <a
              href="https://t.me/amii_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <font-awesome-icon
                :icon="['fab', 'telegram-plane']"
                size="2x"
                class="enlace-icon mx-4"
              />
            </a>
            <a
              href="https://wa.me/584122747740?text=¡Hola%20Soporte%20de%20AMII!,%20¿podemos%20conversar?"
              target="_blank"
              rel="noopener noreferrer"
            >
              <font-awesome-icon
                :icon="['fab', 'whatsapp']"
                size="2x"
                class="enlace-icon"
              />
            </a>
          </div>
        </div>
        <div class="row mx-0 align-items-center justify-content-around pt-5">
          <div class="flex-center px-2 py-3">
            <img
              v-lazy="'/img/soy-medico/amii-logo-mediano-blanco.svg'"
              alt="AMII logo blanco"
            >
            <div class="pl-3">
              <header class="h4-responsive amii-text-style">
                AMII
              </header>
              <small class="d-block font-italic pb-1">
                ¡A la vanguardia de la telemedicina!
              </small>
              <a
                href="https://www.facebook.com/app.amii"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font-awesome-icon
                  :icon="['fab', 'facebook-square']"
                  size="lg"
                  class="enlace-icon mr-4"
                />
              </a>
              <a
                href="https://www.instagram.com/amii.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font-awesome-icon
                  :icon="['fab', 'instagram']"
                  size="lg"
                  class="enlace-icon mr-4"
                />
              </a>
              <a
                href="https://t.me/amii_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font-awesome-icon
                  :icon="['fab', 'telegram-plane']"
                  size="lg"
                  class="enlace-icon mr-4"
                />
              </a>
              <a
                href="https://twitter.com/amii_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font-awesome-icon
                  :icon="['fab', 'twitter-square']"
                  size="lg"
                  class="enlace-icon"
                />
              </a>
            </div>
          </div>
          <div class="col-12 col-lg flex-around py-3">
            <ul class="enlaces px-3">
              <li class="titulo">
                Accesos
              </li>
              <li>
                <a
                  href="https://medico.amii.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Médicos
                </a>
              </li>
              <li>
                <a
                  href="https://paciente.amii.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Paciente
                </a>
              </li>
            </ul>
            <ul class="enlaces px-3">
              <li class="titulo">
                Soporte
              </li>
              <li>
                <router-link
                  v-slot="{ navigate }"
                  :to="{name: 'contactanos'}"
                  custom
                  class="cursor-pointer"
                >
                  <span
                    role="link"
                    @click="navigate"
                  >
                    Contáctanos
                  </span>
                </router-link>
              </li>
              <li>
                <a
                  href="https://t.me/amii_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Telegram
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/584122747740?text=¡Hola%20Soporte%20de%20AMII!,%20¿podemos%20conversar?"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WhatsApp
                </a>
              </li>
            </ul>
            <ul class="enlaces px-3">
              <li class="titulo">
                Enlaces de interés
              </li>
              <li>
                <router-link
                  v-slot="{ navigate }"
                  :to="{name: 'precios'}"
                  custom
                  class="cursor-pointer"
                >
                  <span
                    role="link"
                    @click="navigate"
                  >
                    Precios
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  v-slot="{ navigate }"
                  :to="{name: 'home'}"
                  custom
                  class="cursor-pointer"
                >
                  <span
                    role="link"
                    @click="navigate"
                  >
                    Directorio
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  v-slot="{ navigate }"
                  :to="{name: 'nosotros'}"
                  custom
                  class="cursor-pointer"
                >
                  <span
                    role="link"
                    @click="navigate"
                  >
                    Nosotros
                  </span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <hr>
        <p class="text-center mb-0 pb-3">
          <span class="amii-text-style">AMII</span>
          © {{ currentYear }}
          | Todos los derechos reservados
        </p>
      </div>
    </footer>
  </section>
</template>

<script>
import {mdbBtn} from 'mdbvue';

export default {
  name: 'Footer',
  components: {mdbBtn},
  props: {
    medicoLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
.login-section {
  background-color: $primary-color-dark;
  color: $white;
  padding: 4rem 1rem;
  text-align: center;
}

footer {
  background: linear-gradient(180deg, #002196 0%, #007BFF 100%);
  color: $white;
  overflow-x: hidden;

  .enlace-icon {color: $white;}

  .flex-center {
    align-items: center;
    display: flex;
  }
  .flex-around {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
  }

  ul.enlaces {
    list-style-type: none;
    line-height: 2;

    li.titulo,
    li a {
      color: $white;
    }

    li.titulo {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: .5rem;
      user-select: none;
    }
  }

  .amii-text-style {
    font-weight: 700;
    letter-spacing: .33em;
  }

  hr {border-top-color: $white;}
}
</style>
