<template>
  <div
    id="container-ppal"
    :class="['contenedor-ppal',
             {contenedorfix: contenedor}]"
  >
    <p class="loading-dot">
      Cargando
    </p>
    <div class="contenedor-logo">
      <svg
        id="svg13"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xml:space="preserve"
        width="250mm"
        height="190mm"
        viewBox="0 0 250.00001 152"
        version="1.1"
        sodipodi:docname="logo_amii_250x190mm.svg"
        inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
      >
        <metadata id="metadata17">
          <rdf:RDF>
            <cc:Work rdf:about="">
              <dc:format>image/svg+xml</dc:format>
              <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
            </cc:Work>
          </rdf:RDF>
        </metadata>
        <sodipodi:namedview
          id="namedview15"
          pagecolor="#ffffff"
          bordercolor="#666666"
          borderopacity="1"
          objecttolerance="10"
          gridtolerance="10"
          guidetolerance="10"
          inkscape:pageopacity="0"
          inkscape:pageshadow="2"
          inkscape:window-width="1920"
          inkscape:window-height="1043"
          showgrid="false"
          inkscape:zoom="0.55273973"
          inkscape:cx="270.27005"
          inkscape:cy="270.89198"
          inkscape:window-x="0"
          inkscape:window-y="0"
          inkscape:window-maximized="1"
          inkscape:current-layer="svg13"
        />
        <g
          id="g11"
          transform="translate(-14.078742,-36.541218)"
        >
          <path
            id="nube"
            class="fil0 str0"
            d="M 80.3645,51.4333 C 84.3482,19.1951 36.4712,12.0139
             27.2943,46.8105 c -1.4216,33.8307 46.3886,38.2723
              53.0702,4.6228 -0.2829,18.3891 13.7366,22.1167
               21.2446,8.4157 2.5022,-4.5667 2.8474,-8.8107
                3.9074,-13.7016 6.5131,-30.0453 41.1244,-25.5072
                 40.2493,5.4702 l 0.009,16.4111 -0.009,-16.4111
                  C 147.4692,16.0364 202.508,17.7243 200.26,51.4333
                   l 0.2813,16.4715 -0.2813,-16.4715 c 1.501,-33.2526
                    49.5022,-32.5328 54.7478,-3.8861 0.9657,5.2737
                     1.5125,12.2994 1.6598,19.8872 0.2813,14.4863
                      0.2448,30.639 -1.1508,41.1661 -5.8564,44.1732
                       -76.9434,50.8064 -79.6948,0.0788 1.2135,53.8592
                        -57.7041,51.9733 -64.1966,17.6293 -0.9645,-5.1027
                         -1.4745,-11.006 -1.0196,-17.6815 C 94.6341,180.8337
                          0.3668,136.7911 25.581,53.5973 l 1.7133,-6.7868"
            inkscape:connector-curvature="0"
            style="stroke-linecap:round;stroke-linejoin:round"
          />
          <rect
            id="punto2"
            class="str1 fil1"
            x="173.255"
            y="94.910301"
            width="4.9043598"
            height="4.9675398"
            rx="0.6002"
            ry="0.6002"
            style="fill:#002196;stroke:#002196;stroke-width:6.64440012"
          />
          <rect
            id="punto1"
            class="str1 fil1"
            x="109.55"
            y="94.499298"
            width="4.9043598"
            height="4.9675398"
            rx="0.6002"
            ry="0.6002"
            style="fill:#002196;stroke:#002196;stroke-width:6.64440012"
          />
          <path
            id="esteto"
            class="fil0 str1"
            d="m 142.348,151.551 -0.0997,36.9673 c 0.0327,15.2 18.7399,18.5665
             29.262,6.2739 l 7.0332,-7.5885"
            inkscape:connector-curvature="0"
            sodipodi:nodetypes="cccc"
          />
          <circle
            id="scopio"
            style="stroke-linecap:butt;stroke-linejoin:round;"
            class="str1 fil0"
            cx="-0.74246454"
            cy="-257.70447"
            r="9.7580128"
            transform="rotate(135)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  components: {
  },
  data() {
    return {
      contenedor: true,
    };
  },
  mounted() {
    this.contenedor= false;
    this.$emit('destroy-loader', false);
  },
  methods: {
  },
};
</script>
<style>
  /* Oculta el overflow-y del div adyacente */
  #container-ppal.contenedorfix + div {
    margin-top: 0 !important;
    max-height: 100vh;
    overflow-y: hidden !important;
  }
  .contenedor-ppal {
    align-items: center;
    color: #6c757d;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    height: 95vh;
    justify-content: center;
    text-transform: uppercase;
  }
  .contenedorfix{
    background-color: rgba(255,255,255,0.9);
    min-height: 100vh;
    min-width: 100%;
    position: fixed;
    z-index: 1031;
  }
  .contenedor-logo {
    text-align: center;
    width: 45%;
  }
  @media (min-width: 992px) {
    .contenedor-logo {
      width: 25%;
    }
  }
  svg{
    height: auto;
    width: 100%;
  }
  .fil0 {fill:none}
  .fil1 {fill:#002196}
  .str1 {stroke:#002196;stroke-width:6}
  .str0 {
    stroke:#002196;
    stroke-width:9;
    stroke-linecap:round;
    stroke-linejoin:round;
  }
  /* Fin estilos del svg */
  /* Animacion del logo */
  #nube {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: ani-nube 5s ease-in;
  }
  #punto1 {
    scale: 1;
    transform: scaleY(1);
    animation: ani-show .3s 6s, ani-pica 0.3s 7s linear alternate,
               ani-puntos 6s 10s linear alternate infinite;
    transform-origin: center 65%;
  }
  #punto2 {
    scale: 1;
    transform: scaleY(1);
    animation: ani-show .3s 6s, ani-puntos 6s 10s linear alternate infinite;
    transform-origin: center 65%;
  }
  #esteto {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: ani-esteto 10s linear;
  }
  #scopio {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: ani-scopio 10s ease-out;
  }
  @keyframes ani-nube {
    from {stroke-dashoffset: 1000;}
    to   {stroke-dashoffset: 0;}
  }
  @keyframes ani-esteto {
    0%   {stroke-dashoffset: 1000;}
    50%  {stroke-dashoffset: 1000;}
    70%  {stroke-dashoffset: 0;}
    100% {stroke-dashoffset: 0;}
  }
  @keyframes ani-scopio {
    0%   {stroke-dashoffset: 1000;}
    55%  {stroke-dashoffset: 1000;}
    85%  {stroke-dashoffset: 0;}
    100% {stroke-dashoffset: 0;}
  }
  @keyframes ani-show {
    from {opacity: 0;}
    to   {opacity:1;}
  }
  @keyframes ani-puntos {
    0%   {transform: scaleY(0.1);}
    5%   {transform: scaleY(1);}
    100% {transform: scaleY(1);}
  }
  @keyframes ani-pica {
    from {transform: scaleY(0.1);}
    to   {transform: scaleY(1);}
  }
  /* Fin animacion del logo */
  /* Animación de los puntos en CARGANDO */
  .loading-dot::before,
  .loading-dot::after {
    content: '';
    animation-name: loadingDot;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }
  @keyframes loadingDot {
    0%   {content: '';}
    50%  {content: '.';}
    75%  {content: '..';}
    100% {content: '...';}
  }
  /* Fin animación de los puntos en CARGANDO */
</style>
