<template>
  <div class="mt-5">
    <section class="bg-dotted pt-4">
      <div class="container py-3">
        <div class="row justify-content-end align-items-center mx-n2">
          <p class="col mb-0 h2-responsive">
            Usar nuestro directorio y reservar citas es completamente
            <span class="font-weight-bold">gratis</span>.
          </p>
          <img
            src="/img/vectores/Date-picker-amico.svg"
            alt="Vector alusivo a una persona reservando una cita en AMII."
            class="col-8 col-sm-4 col-lg-5 py-3 py-lg-0"
          >
        </div>
        <header class="h2-responsive font-weight-normal text-center mt-5 mb-3">
          Planes para profesionales de la salud
        </header>
        <div class="row justify-content-center contenedor-planes">
          <div
            v-for="plan in plans"
            :key="plan.id"
            class="col-11 col-sm-8 col-md-4 py-3 px-xl-4"
          >
            <div class="card prices">
              <div class="row mx-1">
                <header class="col px-0 card-title">
                  {{ plan.name }}
                </header>
                <mdb-btn
                  :id="plan.id"
                  type="button"
                  icon="share-alt"
                  icon-class="fa-2x text-muted"
                  flat
                  dark-waves
                  class="col-auto card-share"
                  @click="compartir"
                />
              </div>
              <ul class="benefits-list">
                <li
                  v-for="(benefit, index) in plan.benefits"
                  :key="index"
                >
                  {{ benefit.content }}
                  <mdb-tooltip
                    v-if="benefit.tooltip.length"
                    trigger="hover"
                    :options="{placement: 'left'}"
                  >
                    <div slot="tip">
                      <p class="mb-0">
                        {{ benefit.tooltip }}
                      </p>
                    </div>
                    <a slot="reference">
                      <font-awesome-icon
                        :icon="['fas', 'question-circle']"
                        size="sm"
                        class="d-inline"
                      />
                    </a>
                  </mdb-tooltip>
                </li>
              </ul>
              <div class="price-info mx-2">
                <p class="texto mx-n2">
                  {{ plan.priceTag.texto }}
                </p>
                <div
                  v-if="plan.priceTag.mensual || plan.priceTag.anual"
                  class="precios"
                >
                  <p
                    v-if="plan.priceTag.mensual"
                    class="mb-0 d-inline-block"
                  >
                    <span class="precio">{{ plan.priceTag.mensual }}</span>
                    al mes
                    <a href="#mas-info">
                      <sup class="text-muted font-italic">i</sup>
                    </a>
                  </p>
                  <span v-if="plan.priceTag.mensual && plan.priceTag.anual">
                    |
                  </span>
                  <p
                    v-if="plan.priceTag.anual"
                    class="mb-0 d-inline-block"
                  >
                    <span class="precio">{{ plan.priceTag.anual }}</span>
                    al año
                    <a href="#mas-info">
                      <sup class="text-muted font-italic">ii</sup>
                    </a>
                  </p>
                </div>
              </div>
              <template v-if="plan.enable">
                <div
                  v-if="plan.name === 'Profesional'"
                  class="row justify-content-center mx-2 py-2 border-top"
                >
                  <mdb-btn
                    color="primary-base"
                    class="col-auto py-2 px-md-4 px-lg-5"
                    @click="$router.push('/soy-medico#formularioRegistro');"
                  >
                    ¡Registrarme!
                  </mdb-btn>
                </div>
                <div
                  v-else
                  class="row justify-content-center mx-2 py-2 border-top"
                >
                  <mdb-btn
                    outline="primary-base"
                    class="col-auto py-2 px-md-4 px-lg-5 z-depth-0"
                    @click="$router.push('/soy-medico#formularioRegistro');"
                  >
                    ¡Registrarme!
                  </mdb-btn>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container py-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-10 col-sm-5">
          <img
            src="/img/vectores/Conversation-pana.svg"
            alt="Vector de personas compartiendo información sobre AMII"
          >
        </div>
        <div class="col px-5 px-sm-3">
          <header class="h3-responsive mb-3">
            Obtén descuento al compartir
            <span class="font-weight-bold">AMII</span>
            con tus colegas médicos
          </header>
          <p class="text-muted font-italic mb-0">
            Por cada <span class="font-weight-bold">referido activo</span>
            <a href="#mas-info"><sup class="text-muted font-italic">*</sup></a>
            obtén $1 al mes de descuento en tu plan. Si sobre pasas el costo
            de tu plan acumulas $0.50 al mes de ganancia por cada referido
            adicional, la cual puedes retirar al llegar a $20.
          </p>
        </div>
      </div>
      <ul
        id="mas-info"
        class="definition-list border-top"
      >
        <li
          v-for="refencia in definitionList"
          :key="refencia.referencia"
          :data-reference="refencia.referencia"
        >
          {{ refencia.definicion }}
        </li>
      </ul>
    </section>
  </div>
</template>
<script>
import {mdbBtn, mdbTooltip} from 'mdbvue';
export default {
  name: 'Precios',
  components: {
    mdbBtn,
    mdbTooltip,
  },
  data() {
    return {
      plans: [
        {
          id: 'gt-share-plan-basico',
          name: 'Básico',
          enable: true,
          priceTag: {
            texto: '¡Gratis!',
            mensual: '',
            anual: '',
          },
          benefits: [
            {
              content: 'Oferta de servicios en directorio',
              tooltip: '',
            },
            {
              content: 'Perfil profesional con enlace único',
              tooltip: 'Comparte y publica tu enlace en tus redes sociales '
                + 'para que tus pacientes puedan solictar citas y ver tus '
                + 'datos de contacto y tus redes sociales',
            },
            {
              content: 'Gestión de citas reservadas a través del directorio y '
                + 'perfil personalizado',
              tooltip: 'Los pacientes pueden reservar citas en tus '
                + 'consultorios través del buscador online en amii.app o a '
                + 'través de la aplicación móvil de cada paciente',
            },
          ],
        },
        {
          id: 'gt-share-plan-profesional',
          name: 'Profesional',
          enable: true,
          priceTag: {
            texto: '¡Aprovecha la promoción!',
            mensual: '$20',
            anual: '$200',
          },
          benefits: [
            {
              content: 'Pack del plan básico',
              tooltip: '',
            },
            {
              content: 'Gestión de pacientes y diagnósticos',
              tooltip: '',
            },
            {
              content: 'Historial médico',
              tooltip: '',
            },
            {
              content: 'Formatos de impresión de recipe personalizables',
              tooltip: '',
            },
            {
              content: 'Informe financiero contable',
              tooltip: 'Genera informes contables que pueden ir directamente a '
                + 'tu contador o personal encargado de tus finanzas para '
                + 'facilitar pago de impuestos, entre otros',
            },
            {
              content: 'Gestión de asistentes',
              tooltip: 'Agrega asistentes (o recepcionistas) que te ayuden a '
                + 'visualizar, responder, agregar citas y nuevos pacientes. '
                + 'Ellos solo verán la información que agregan, a los demás '
                + 'datos solo tú tienes acceso',
            },
            {
              content: 'Realiza estudios de Epidemiología',
              tooltip: '',
            },
          ],
        },
        {
          id: 'gt-share-plan-maestro',
          name: 'Maestro',
          enable: false,
          priceTag: {
            texto: '¡Próximamente!',
            mensual: '',
            anual: '',
          },
          benefits: [
            {
              content: 'Pack del plan profesional',
              tooltip: '',
            },
            {
              content: 'Gestión de archivos dicom',
              tooltip: '',
            },
            {
              content: 'Integración con laboratorios',
              tooltip: 'Visualiza el resultado de los éxamenes o estudios que '
                + 'hayas ordenado hacer sin esperas. Cuando un laboratorio o '
                + 'centro de análisis registrado en AMII cargue los datos de '
                + 'los resultados de tus pacientes, te llegará una '
                + 'notificación y podrás verlo de inmediato',
            },
            {
              content: 'Sugerencias según inteligencia artificial',
              tooltip: 'Obtén al instante sugerencias de posibles diagnósticos '
                + 'al registrar los síntomas. Nuestra Inteligencia Artificial '
                + 'estudia por ti los patrones que pueden indicar la presencia '
                + 'de alguna enfermedad y te muestra las probabilidades',
            },
            {
              content: 'Chat y vídeo chat con pacientes',
              tooltip: 'Establece un precio por tu consulta online y atiende '
                + 'pacientes vía chat o vídeo chat cuando sea necesario',
            },
          ],
        },
      ],
      definitionList: [
        {
          referencia: '*',
          definicion: 'Referido activo hace mención a un usuario del sistema '
            + 'que se registre usando tu código de invitación, esté al día con '
            + 'los pagos y haya realizado al menos dos pagos.',
        },
        {
          referencia: 'i',
          definicion: 'Un pago por cada mes que se quiera contratar la '
            + 'aplicación.',
        },
        {
          referencia: 'ii',
          definicion: 'Un pago por todo un año que se quiera contratar la '
            + 'aplicación.',
        },
      ],
    };
  },
  methods: {
    compartir(event) {
      if (navigator.share) {
        navigator.share({
          title: 'AMII Asistente Médico Integral Inteligente',
          text: 'Mira esta app de gestión para médicos y reserva de '
            +'citas para pacientes',
          url: 'https://amii.app/soy-medico/#comparte',
        })
            .then(() => {
              try {
                this.$gtag.event('share', {
                  'content_type': 'url',
                  'content_id': event.target.id,
                });
              // eslint-disable-next-line no-empty
              } catch (error) {}
            })
            .catch((error) => alert('Error', error));
      } else {
        alert('Esta opción solo está disponible para navegadores en móviles, o '
        +'tú navegador no es compatible. Recomendamos las últimas'
        +' versiones de Chrome, Firefox o Safari.');
      }
    },
  },
};
</script>
<style lang="scss">
  .bg-dotted {
    background-color: rgba(255, 255, 255, 0);
    background-image: radial-gradient(rgba(0, 123, 255, 0.2) 0.8px,
      rgba(255, 255, 255, 0) 0.8px);
    background-size: 16px 16px;
  }
  .contenedor-planes {
    padding-bottom: 1rem;

    :first-child .card.prices{
      border-radius: 1rem .25rem .25rem 1rem;
    }
    :last-child .card.prices{
      border-radius:.25rem 1rem 1rem .25rem;
    }
  }
  .card.prices {
    box-shadow: 0 0 6px rgba(0,33,150,.25), 0 3px 6px rgba(0,33,150,.25);
    padding: 1rem;

    .card-title {
      font-size: 1.5rem;
      font-weight: 700;
    }
    .card-share {
      margin: 0 0 .75rem 0;
      padding: .25rem 0 .25rem 1rem;

      @media screen and (min-width: 768px) and (max-width: $max-medium){
        padding-left: .05rem;
      }
    }
    .benefits-list {
      list-style: none;
      margin: 0 .25rem .5rem;
      padding-left: 1.1rem;
      li {
        color: $gris-muted;
        font-size: .85rem;
        font-style: italic;
        margin-bottom: .5rem;
        position: relative;

        &::before {
          color: $success-color;
          content: '\f058';
          font-family: 'Font Awesome 5 Free';
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          position: absolute;
          left: -18px;
        }
      }
    }
    .price-info {
      .texto {
        font-size: 1.3rem;
        font-weight: 700;
        text-align: center;
      }
      .precios {
        color: $gris-muted;
        text-align: center;
        font-style: italic;
        margin: -.75rem 0 .5rem;
      }
      .precio {
        color: initial;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
  .definition-list {
    list-style: none;
    margin: 2rem 0 0;
    padding-top: 1.5rem;

    li {
      color: $gris-muted;
      font-size: .75rem;
      font-style: italic;
      padding-left: 1rem;
      position: relative;

      &::before {
        content: attr(data-reference);
        position: absolute;
        left: 0;
      }
    }
  }
  .border-top {
    border-top: 1px solid $gris-separador;
  }
</style>
