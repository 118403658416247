<template>
  <transition name="fade">
    <div
      v-if="alertaMsj.content && alertaMsj.content.length"
      class="alert-fixed"
      @click="cerrarAlerta"
    >
      <mdb-alert
        :class="['alert mx-2 cursor-pointer', class_color]"
        dismiss
        close-class="ml-2"
        @closeAlert="cerrarAlerta"
      >
        <font-awesome-icon
          :icon="[iconPrefix, icon]"
          style="font-size: 1rem;"
          class="mr-2"
        /> {{ alertaMsj.content }}
      </mdb-alert>
    </div>
  </transition>
</template>
<script>
import {mdbAlert} from 'mdbvue';
export default {
  name: 'AlertaMsj',
  components: {
    mdbAlert,
  },
  props: {
    alertaMsj: {
      type: Object,
      default: function() {
        return {
          content: '',
        };
      },
    },
    tiempoAutoOcultar: {
      type: Number,
      default: 4500,
    },
  },
  data() {
    return {
      controlTemporizador: '',
    };
  },
  computed: {
    class_color() {
      if (this.alertaMsj.type) {
        return this.alertaMsj.type == 'success'
          ? 'alert-success-custom'
          : this.alertaMsj.type == 'warning'
            ? 'alert-warning-custom'
            : 'alert-danger-custom';
      } else {
        return this.alertaMsj.class_color;
      }
    },
    iconPrefix() {
      return this.alertaMsj.type == 'success'
          ? 'far'
          : this.alertaMsj.iconPrefix || 'fas';
    },
    icon() {
      if (this.alertaMsj.type) {
        return this.alertaMsj.type == 'success'
          ? 'thumbs-up'
          : this.alertaMsj.type == 'warning'
            ? 'exclamation-triangle'
            : 'exclamation-triangle';
      } else {
        return this.alertaMsj.icon;
      }
    },
  },
  watch: {
    alertaMsj: function() {
      clearTimeout(this.controlTemporizador);
      this.controlTemporizador = setTimeout(
          () => this.cerrarAlerta(),
          this.tiempoAutoOcultar,
      );
    },
  },
  methods: {
    cerrarAlerta() {
      clearTimeout(this.controlTemporizador);
      this.$emit('cerrar');
    },
  },
};
</script>
<style lang="scss">
  .alert-fixed {
    position: fixed;
    margin-top: 1rem;
    right: 1rem;
    top: 1rem;
    z-index: 2000;
  }

  .fade-enter-active {
    transition: all .2s ease;
  }
  .fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to {
    transform: translateX(5px);
    opacity: 0;
  }
</style>
