import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/pages/Home.vue';
import Nosotros from './views/pages/Nosotros.vue';
import SoyMedico from './views/pages/SoyMedico.vue';
import Precios from './views/pages/Precios.vue';
import Contactanos from './views/pages/Contactanos.vue';

Vue.use(Router);

/**
 * Crear Router con todas las rutas de la App
 * @return {createRouter} Router
 */
export function createRouter() {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          selector: to.hash,
          offset: {x: 0, y: 10},
        };
      } else {
        return {x: 0, y: 0};
      }
    },
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {title: 'AMII Asistente Médico'},
      },
      {
        path: '/nosotros',
        name: 'nosotros',
        component: Nosotros,
        meta: {title: 'AMII Nosotros'},
      },
      {
        path: '/revista',
        component: () => import('./views/pages/RevistaRV.vue'),
        props: true,
        children: [
          {
            path: '/',
            name: 'revista',
            component: () => import('./views/pages/Revista.vue'),
            meta: {title: 'AMII Revista'},
          },
          {
            path: ':id',
            name: 'articulo',
            component: () => import('./views/pages/Articulo.vue'),
            meta: {title: 'AMII Artículo'},
          },
        ],
      },
      {
        path: '/soy-medico',
        name: 'soyMedico',
        component: SoyMedico,
        meta: {title: 'AMII Soy Médico'},
      },
      {
        path: '/soy-medico/:code',
        component: SoyMedico,
        meta: {title: 'AMII Soy Médico'},
      },
      {
        path: '/precios',
        name: 'precios',
        component: Precios,
        meta: {title: 'AMII Precios'},
      },
      {
        path: '/eventos',
        name: 'eventos',
        component: () => import('./views/pages/Eventos.vue'),
        meta: {title: 'AMII Eventos'},
      },
      {
        path: '/eventos/:id',
        name: 'evento',
        component: () => import('./views/pages/Evento.vue'),
        meta: {title: 'AMII Evento'},
      },
      {
        path: '/contactanos',
        name: 'contactanos',
        component: Contactanos,
        meta: {title: 'AMII Contáctanos'},
      },
      {
        path: '/_/:usuario',
        name: 'PerfilDeUsuario',
        component: () => import('./views/pages/PerfilDeUsuario.vue'),
      },
      {
        path: '*',
        name: 'default',
        component: Home,
        meta: {title: 'AMII Asistente Médico'},
      },
    ],
  });

  const defaultTitle = 'AMII Asistente Médico';
  router.afterEach((to, from) => {
    Vue.nextTick(() => {
      if (typeof document != 'undefined') {
        document.title = to.meta.title || defaultTitle;
      }
    });
  });

  return router;
}
