import {library} from '@fortawesome/fontawesome-svg-core';
import {faArrowAltCircleDown, faBullhorn, faCalendarAlt, faChevronLeft,
  faChevronRight, faDollarSign, faHome, faLevelDownAlt, faNewspaper,
  faPhoneSquareAlt, faUser, faUserFriends, faUserMd, faPhoneAlt, faPlus,
  faUsers, faVenusMars, faEye, faEyeSlash, faHospital, faMapMarkerAlt,
  faClock, faExclamationTriangle, faStopwatch, faAngleDown, faEnvelope,
  faCheck, faAngleRight, faTimes, faArrowAltCircleUp, faArrowAltCircleRight,
  faQuestionCircle, faShareAlt, faPen, faCircleNotch, faCheckSquare,
  faUserSlash, faPhone, faAngleLeft, faSignInAlt, faQuoteLeft, faQuoteRight,
  faPlay, faHandshake, faDiagnoses, faCheckCircle, faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faInstagram, faTwitterSquare, faTelegramPlane,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {faThumbsUp, faSquare} from '@fortawesome/free-regular-svg-icons';

library.add(faArrowAltCircleDown, faBullhorn, faCalendarAlt, faChevronLeft,
    faChevronRight, faDollarSign, faHome, faLevelDownAlt, faNewspaper,
    faPhoneSquareAlt, faUser, faUserFriends, faUserMd, faPhoneAlt, faPlus,
    faUsers, faVenusMars, faEye, faEyeSlash, faHospital, faMapMarkerAlt,
    faClock, faExclamationTriangle, faStopwatch, faAngleDown, faEnvelope,
    faCheck, faAngleRight, faTimes, faArrowAltCircleUp, faArrowAltCircleRight,
    faQuestionCircle, faShareAlt, faPen, faCircleNotch, faCheckSquare,
    faUserSlash, faPhone, faAngleLeft, faSignInAlt, faQuoteLeft, faQuoteRight,
    faPlay, faHandshake, faDiagnoses, faCheckCircle, faPaperPlane,
    // Brands
    faFacebookSquare, faInstagram, faTwitterSquare, faTelegramPlane,
    faWhatsapp,
    // Regular
    faThumbsUp, faSquare,
);
