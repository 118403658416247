<template>
  <div
    :class="['md-form', className,
             {'class-valid': validateValue == 'valid'},
             {'class-invalid': validateValue == 'invalid'}]"
  >
    <select
      :id="`${nameSelect ? nameSelect + '-' : ''}gender-select`
        + `${identificador ? '-' + identificador : ''}`"
      ref="selectGender"
      :value="value"
      class="custom-select"
      @change="changed($event.target.value)"
    >
      <option
        v-for="(genderOpt, i) in genderOptions"
        :key="`${i}${nameSelect ? '-' + nameSelect : ''}`
          + `${identificador ? '-' + identificador : ''}`"
        :value="genderOpt.value"
        :class="{ 'd-none': genderOpt.value == '' }"
      >
        {{ genderOpt.name }}
      </option>
    </select>
    <label
      :for="`${nameSelect ? nameSelect + '-' : ''}gender-select`
        + `${identificador ? '-' + identificador : ''}`"
      :class="[value && value.length ? 'label-active' : 'label-inactive']"
    >
      Género
    </label>
  </div>
</template>
<script>
export const SelectGender = {
  name: 'SelectGender',
  props: {
    // Clase del div principal
    className: {
      type: String,
      required: true,
    },
    identificador: {
      type: [String, Number],
      default: '',
    },
    nameSelect: {
      type: String,
      default: '',
    },
    validateValue: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      genderOption: {
        name: 'Seleccione',
        value: '',
      },
      genderOptions: [
        {
          name: 'Seleccione',
          value: '',
        },
        {
          name: 'Femenino',
          value: 'Femenino',
        },
        {
          name: 'Masculino',
          value: 'Masculino',
        },
        {
          name: 'Desconocido',
          value: 'Desconocido',
        },
        {
          name: 'Otro',
          value: 'Otro',
        },
      ],
      genderIcons: [
        {
          name: 'venus',
          value: 'Femenino',
        },
        {
          name: 'mars',
          value: 'Masculino',
        },
        {
          name: 'genderless',
          value: 'Desconocido',
        },
        {
          name: 'transgender',
          value: 'Otro',
        },
      ],
      genderIconColors: [
        {
          name: 'pink-text',
          value: 'Femenino',
        },
        {
          name: 'blue-text',
          value: 'Masculino',
        },
        {
          name: 'grey-text',
          value: 'Desconocido',
        },
        {
          name: 'purple-text',
          value: 'Otro',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.selectGender.focus();
    },
    changed(value) {
      this.$emit('input', value);
      this.genderOption = this.genderOptions.filter((i) =>
        i.value == value)[0];
    },
  },
};

const genderOptions = {};
SelectGender.data().genderOptions.forEach((e) => {
  genderOptions[e.value] = e.name;
});
const genderIcons = {};
SelectGender.data().genderIcons.forEach((e) => {
  genderIcons[e.value] = e.name;
});
const genderIconColors = {};
SelectGender.data().genderIconColors.forEach((e) => {
  genderIconColors[e.value] = e.name;
});
export {genderOptions, genderIcons, genderIconColors};
export default SelectGender;
</script>
<style lang="scss">

</style>
