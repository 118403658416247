<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="1"
      height="20"
      transform="translate(0 11) rotate(-90)"
    />
    <rect
      width="1"
      height="20"
      transform="translate(10)"
    />
  </svg>
</template>

<script>
// Se emplea en Contactanos.vue section#FAQs
export default {
  name: 'Plus',
};
</script>
