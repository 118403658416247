<template>
  <section>
    <header class="h2-responsive text-center mb-2 mt-4 pt-5">
      ¡Regístrate hoy!
    </header>
    <p class="lead text-center text-muted font-italic mb-5">
      Y sé parte de nuestra comunidad de médicos a la vanguardia de
      <span class="font-weight-bold primary-dark-text">AMII</span>
    </p>
    <div
      class="card mx-auto mb-5 col-11 col-md-10 col-lg-11 col-xl-9
        py-4 px-3 px-sm-4 overflow-x-hidden"
    >
      <header class="h4-responsive">
        Formulario de registro para médicos
      </header>
      <form
        @submit.prevent
      >
        <transition
          mode="out-in"
          name="fading-classes-transition"
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOutLeft"
        >
          <ul
            v-if="step !== 5"
            key="statusStepper"
            class="list-unstyled d-flex justify-content-center"
          >
            <li
              v-for="i in steps-1"
              :key="i"
            >
              <span
                :class="['step', {'actual' : i=== step },
                         {'bg-success-base': i < step}]"
              />
            </li>
          </ul>
          <p
            v-else
            key="finish"
            class="text-center"
          >
            <font-awesome-icon
              icon="check-circle"
              class="fa-5x my-4 animated rotateIn delay-1s success-text"
            />
          </p>
        </transition>
        <transition
          mode="out-in"
          name="fading-classes-transition"
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOutLeft"
        >
          <fieldset
            v-if="step === 1"
            key="step1"
            class="row"
          >
            <legend class="h5-responsive col-12 my-2">
              Información personal
            </legend>
            <p class="col-12 text-muted">
              ¿Cómo te verán tus pacientes?
            </p>
            <div class="col-sm-6 col-md-4">
              <mdb-input
                v-model="newDoc.fname"
                type="text"
                label="Primer nombre"
                :class="[
                  'my-2',
                  {'class-valid': validateValue.fname === 'valid'},
                  {'class-invalid': validateValue.fname === 'invalid'}]"
                outline
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <mdb-input
                v-model="newDoc.mname"
                type="text"
                label="Segundo nombre"
                class="my-2"
                outline
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <mdb-input
                v-model="newDoc.lname"
                type="text"
                label="Primer apellido"
                :class="[
                  'my-2',
                  {'class-valid': validateValue.lname === 'valid'},
                  {'class-invalid': validateValue.lname === 'invalid'}]"
                outline
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <mdb-input
                v-model="newDoc.llname"
                type="text"
                label="Segundo apellido"
                class="my-2"
                outline
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <SelectGender
                v-model="newDoc.gender"
                class-name="md-outline outline-select my-2"
                name-select="newDoc"
                :validate-value="validateValue.gender"
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <mdb-input
                v-model="newDoc.born"
                type="date"
                label="F de nacimiento"
                :class="[
                  'my-2 outline-date',
                  {'class-valid': validateValue.born === 'valid'},
                  {'class-invalid': validateValue.born === 'invalid'}]"
                outline
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <SelectIdentificationType
                ref="componentSelectIdentificationtype"
                v-model="newDoc.identificationType"
                class-name="md-outline outline-select my-2"
                :validate-value="validateValue"
                @save="identificationType=$event;
                       newDocIdentificationInitialValue()"
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <mdb-input
                ref="inputIdentification"
                v-model="newDoc.identification"
                type="text"
                label="Identificación"
                :class="[
                  'my-2',
                  {'class-valid': validateValue.identification === 'valid'},
                  {'class-invalid':
                    validateValue.identification === 'invalid'}]"
                outline
              />
            </div>
          </fieldset>
          <fieldset
            v-if="step === 2"
            key="step2"
            class="row"
          >
            <legend class="h5-responsive col-12 my-2">
              Información de contacto
            </legend>
            <p class="col-12 text-muted">
              Para recibir notificaciones importantes de tu cuenta AMII
            </p>
            <div class="col-sm-5 col-lg-6">
              <mdb-input
                v-model="email"
                type="email"
                label="Email principal"
                :class="[
                  'my-2',
                  {'class-valid': validateValue.email === 'valid'},
                  {'class-invalid': validateValue.email === 'invalid'}]"
                outline
              />
            </div>
            <div class="col-sm d-flex px-0">
              <div class="col-4 pr-0">
                <CustomDatalist
                  id="telCode"
                  v-model="telCountry"
                  :list="telCodes"
                  :class-name="['my-2',
                                {'class-valid':
                                  validateValue.telCode == 'valid'},
                                {'class-invalid':
                                  validateValue.telCode == 'invalid'}]"
                  label="País"
                  type="number"
                  :outline="true"
                  @save="telCountry=$event.code;"
                />
              </div>
              <div class="col pl-0">
                <mdb-input
                  v-model="tel"
                  type="tel"
                  label="Teléfono principal"
                  :class="[
                    'my-2',
                    {'class-valid': validateValue.tel === 'valid'},
                    {'class-invalid': validateValue.tel === 'invalid'}]"
                  outline
                />
              </div>
            </div>
          </fieldset>
          <fieldset
            v-if="step === 3"
            key="step3"
            class="row"
          >
            <legend class="h5-responsive col-12 my-2">
              Información profesional
            </legend>
            <p class="col-12 text-muted">
              Cuéntanos tu especialidad,
              <span class="font-italic">
                ¿Eres médico general, pediatra, obstetra,...?
              </span>
              <small class="d-block text-muted">
                Validamos esta información para evitar
                ofrecer a los pacientes falsos profesionales
                en nuestra plataforma.
              </small>
            </p>
            <div class="col-sm">
              <mdb-input
                v-model="degree.name"
                type="text"
                label="Título Obtenido"
                :class="[
                  'my-2',
                  {'class-valid': validateValue.degreeName === 'valid'},
                  {'class-invalid': validateValue.degreeName === 'invalid'}
                ]"
                outline
              />
            </div>
            <div class="col-sm input-group">
              <mdb-input
                v-model="degree.code"
                type="text"
                label="Matrícula M.P.P.S."
                :class="[
                  'col p-0 my-2',
                  {'class-valid': validateValue.degreeCode === 'valid'},
                  {'class-invalid': validateValue.degreeCode === 'invalid'}]"
                outline
              />
              <mdb-tooltip
                trigger="hover"
                class="input-group-text md-addon mb-2"
                :options="{placement: 'left'}"
              >
                <div slot="tip">
                  <p class="mb-0">
                    La matrícula M.P.P.S. es el número de licencia que te
                    autoriza como médico en Venezuela. Coloca tu número
                    de licencia según tu país.
                  </p>
                </div>
                <a slot="reference">
                  <font-awesome-icon
                    :icon="['fas', 'question-circle']"
                    size="sm"
                    class="d-inline"
                  />
                </a>
              </mdb-tooltip>
            </div>
          </fieldset>
          <fieldset
            v-if="step === 4"
            key="step4"
            class="row justify-content-center"
          >
            <legend class="h5-responsive col-12 my-2 text-center">
              Elige tu contraseña AMII
            </legend>
            <div>
              <mdb-input
                v-model="newDoc.identification"
                label="Usuario"
                class="my-2"
                disabled
                outline
              />
            </div>
            <div class="col-sm-6 col-lg-4 d-flex align-items-center">
              <mdb-input
                v-model="password"
                :type="passwordType"
                label="Contraseña"
                :class="[
                  'my-2',
                  {'class-valid': validateValue.password === 'valid'},
                  {'class-invalid': validateValue.password === 'invalid'}
                ]"
                outline
              />
              <font-awesome-icon
                :icon="
                  passwordType == 'password'
                    ? 'eye' : 'eye-slash'"
                class="ml-2 mb-2 cursor-pointer"
                @click="
                  passwordType == 'password'
                    ? passwordType = 'text'
                    : passwordType = 'password'
                "
              />
            </div>
          </fieldset>
          <fieldset
            v-if="step === 5"
            key="step5"
            class="text-center"
          >
            <legend class="h4-responsive my-2 text-center">
              ¡Felicidades!
            </legend>
            <div class="mb-3">
              <p>
                Ya casi eres un médico AMII, revisa en tu bandeja de
                <span class="d-sm-block">
                  entrada del correo {{ emailTemp }} en busca del mensaje de
                  validación de AMII.
                </span>
              </p>
              <p>
                Abre el mensaje y pulsa el botón Validar correo, eso
                <span class="d-sm-block">
                  te llevará a tu inicio de sesión como Profesional AMII.
                </span>
              </p>
              <small>
                Si ya validaste tu correo, puedes iniciar
                <span class="d-sm-block fs-inherit">
                  sesión haciendo clic en el siguiente botón:
                </span>
              </small>
            </div>
            <a
              href="https://medico.amii.app"
              target="_self"
              rel="noopener noreferrer"
            >
              <mdb-btn
                color="primary-base"
                icon="user"
              >
                Iniciar sesión
              </mdb-btn>
            </a>
          </fieldset>
        </transition>
        <p
          v-if="step === 4"
          class="mb-0 mt-2 text-center animated fadeInUp delay-1s"
        >
          <span
            :class="['py-2 px-3 fake-checkbox',
                     {'active': checkTerm}]"
            @click="$emit('show-terms')"
          >
            Acepto los términos y condiciones
          </span>
        </p>
        <div
          class="card-buttons mt-3"
        >
          <div v-if="step < steps-1">
            <mdb-btn
              color="primary-base"
              icon="angle-right"
              icon-right
              @click="next"
            >
              Siguiente
            </mdb-btn>
          </div>
          <div v-else-if="step !== 5">
            <BtnLoader
              ref="btnDoctorSignup"
              btn-classes="col-auto"
              btn-color="success-base"
              :btn-disabled="registerBtnDisabled"
              btn-title="Registrarse"
              @click="next"
            />
          </div>
          <div v-if="step > 1">
            <mdb-btn
              flat
              dark-waves
              class="flat-bordered"
              icon="angle-left"
              @click="step === 5 ? step = 1: step--"
            >
              {{ step === 5 ? 'Volver al formulario': 'Regresar' }}
            </mdb-btn>
          </div>
        </div>
        <div class="row mt-3">
          <div
            ref="divMostrarCupon"
            class="col-md-6 justify-aligning-center text-muted
              cursor-pointer"
            @click="mostrarCupon = !mostrarCupon"
          >
            <p>
              ¿Tienes un código de invitación?
              <br>
              ¡Agrégalo
              <u>aquí</u>
              y obtén descuento!
            </p>
          </div>
          <transition
            name="custom-classes-transition"
            enter-active-class="animated headShake"
            leave-active-class="animated bounceOut"
            @after-enter="$refs.cuponInput.focus()"
          >
            <div
              v-if="mostrarCupon"
              class="col-md-6 justify-aligning-center"
            >
              <mdb-input
                ref="cuponInput"
                v-model="cupon"
                type="text"
                label="Código"
                :class="[
                  'my-2',
                  {'class-valid': validateValue.cupon === 'valid'},
                  {'class-invalid': validateValue.cupon === 'invalid'}]"
                outline
              />
            </div>
          </transition>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import {mdbBtn, mdbInput, mdbTooltip} from 'mdbvue';
import gql from 'graphql-tag';
import {validateIdentification, emailSchema, capitalizeAll, personSchema}
from '@/utils/validations.js';
import SelectIdentificationType
from '@/components/SelectIdentificationType.vue';
import SelectGender
from '@/components/customFields/SelectGender.vue';
import doctorSignupGql from '../graphql/doctorSignup.gql';
import {telCodes} from '@/constantes/telephoneCodes.js';


export default {
  name: 'FormularioRegistro',
  components: {
    BtnLoader,
    CustomDatalist,
    mdbBtn,
    mdbInput,
    mdbTooltip,
    SelectIdentificationType,
    SelectGender,
  },
  props: {
    checkTerm: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      telCodes,
      newDoc: {
        gender: '',
        identification: '',
      },
      tel: '',
      telCountry: '',
      email: '',
      emailTemp: '',
      password: '',
      degree: {},
      validateValue: {},
      step: 1,
      steps: 5,
      registerBtnDisabled: false,
      cupon: '',
      mostrarCupon: false,
      passwordType: 'password',
    };
  },
  watch: {
    code() {
      this.mostrarCupon = true;
      this.cupon = this.code;
    },
  },
  methods: {
    next() {
      switch (this.step) {
        case 1:
          if (!this.newDoc.fname
            || !this.newDoc.lname
            || !this.newDoc.gender
            || !this.newDoc.born
            || !this.newDoc.identificationType
            || !this.newDoc.identification
          ) {
            this.validateValue = {
              fname: this.newDoc.fname
                ? 'valid'
                : 'invalid',
              lname: this.newDoc.lname
                ? 'valid'
                : 'invalid',
              gender: this.newDoc.gender
                ? 'valid'
                : 'invalid',
              born: this.newDoc.born
                ? 'valid'
                : 'invalid',
              identificationType: this.newDoc.identificationType
                ? 'valid'
                : 'invalid',
              identification: this.newDoc.identification
                ? 'valid'
                : 'invalid',
            };
            return this.$emit('alerta-msj', {
              content: 'Los campos resaltados en rojo son requeridos',
              type: 'warning',
            });
          }
          if (!personSchema.isValidSync(this.newDoc)) {
            this.validateValue.born = 'invalid';
            return this.$emit('alerta-msj', {
              content: 'Tu fecha de nacimiento debe ser antes de hoy',
              type: 'warning',
            });
          }
          if (!validateIdentification(this.identificationType,
              this.newDoc.identification)
              || !this.newDoc.identificationType) {
            this.validateValue.identification = 'invalid';
            return this.$emit('alerta-msj', {
              content: this.identificationType.mensaje,
              type: 'warning',
            });
          } else {
            // Formato a los nombres y apellidos del médico
            this.newDoc.fname = capitalizeAll(this.newDoc.fname);
            this.newDoc.lname = capitalizeAll(this.newDoc.lname);
            if (this.newDoc.mname && this.newDoc.mname.length) {
              this.newDoc.mname = capitalizeAll(this.newDoc.mname);
            }
            if (this.newDoc.llname && this.newDoc.llname.length) {
              this.newDoc.llname = capitalizeAll(this.newDoc.llname);
            }
            this.validateValue = {
              fname: 'valid',
              lname: 'valid',
              gender: 'valid',
              born: 'valid',
              identificationType: 'valid',
              identification: 'valid',
            },
            this.step++;
          }
          break;
        case 2:
          if (!this.email) {
            this.validateValue = {
              email: this.email
                ? 'valid'
                : 'invalid',
            };
            return this.$emit('alerta-msj', {
              content: 'El email es requerido',
              type: 'warning',
            });
          }
          if (!emailSchema.isValidSync({email: this.email})) {
            this.validateValue.email = 'invalid';
            return this.$emit('alerta-msj', {
              content: 'Email inválido',
              type: 'warning',
            });
          } else {
            this.validateValue.email = 'valid';
          }
          if (!this.tel && this.telCountry ) {
            this.validateValue.telCode = 'valid';
            this.validateValue.tel = 'invalid';
            return this.$emit('alerta-msj', {
              content: 'Debes agregar un número telefonico '
              + 'para el codigo de tu país',
              type: 'warning',
            });
          } else {
            this.validateValue.tel = 'valid';
          }
          if (this.tel && !this.telCountry ) {
            this.validateValue.tel = 'valid';
            this.validateValue.telCode = 'invalid';
            return this.$emit('alerta-msj', {
              content: 'Debes agregar un código telefonico',
              type: 'warning',
            });
          } else {
            this.validateValue.telCode = 'valid';
          }
          this.step++;
          break;
        case 3:
          if (!this.degree.name || !this.degree.code) {
            this.validateValue = {
              degreeName: this.degree.name
                ? 'valid'
                : 'invalid',
              degreeCode: this.degree.code
                ? 'valid'
                : 'invalid',
            };
            return this.$emit('alerta-msj', {
              content: 'Título y Matrícula son campos requeridos',
              type: 'warning',
            });
          }
          // Formato al degree.name
          this.degree.name = capitalizeAll(this.degree.name);

          this.validateValue = {
            degreeCode: 'valid',
            degreeName: 'valid',
          };
          this.step++;
          break;
        case 4:
          if (!this.password) {
            this.validateValue = {
              password: this.password
                ? 'valid'
                : 'invalid',
            };
            return this.$emit('alerta-msj', {
              content: 'La contraseña es requerida',
              type: 'warning',
            });
          }
          this.validateValue = {
            password: 'valid',
          };
          if (!this.checkTerm) {
            return this.$emit('alerta-msj', {
              content: 'Lee y acepta nuestros términos y condiciones.',
              type: 'warning',
            });
          }
          this.doctorSignup();
          break;
        default:
          this.step++;
          break;
      }
      if (this.cupon && this.cupon.length < 8) {
        this.validateValue.cupon = 'invalid';
        return this.$emit('alerta-msj', {
          content: 'El código debe tener 8 caracteres',
          type: 'warning',
        });
      }
      this.validateValue = {
        cupon: 'valid',
      };
    },
    doctorSignup() {
      this.registerBtnDisabled = true;

      this.$apollo.mutate({
        mutation: gql`${doctorSignupGql}`,
        variables: {
          doctor: this.newDoc,
          password: this.password,
          degree: {
            code: this.degree.code,
            name: this.degree.name,
          },
          email: this.email,
          tel: {
            number: this.tel,
            country: this.telCountry,
          },
          invitationCode: this.cupon,
        },
      }).then((res) => {
        if (res.data.doctorSignup.msg == 'email_confirmation_required') {
          this.newDoc = {
            gender: '',
            identification: '',
          };
          this.degree = {};
          this.tel = '';
          this.password = '';
          this.cupon = '';
          this.emailTemp = this.email;
          this.email = '';
          this.validateValue = {};
          this.$emit('clean-check-term');
          this.$emit('alerta-msj', {
            content: 'Por favor confirma tu correo electrónico haciendo clic'
            + ' en el email recibido y luego usa la sección de Iniciar sesión',
            type: 'warning',
          });
          this.step++;
        }
        if (res.data.doctorSignup.msg == 'user_already_register') {
          this.$emit('alerta-msj', {
            content: 'Este usuario ya existe. Si '
            + 'olvidaste tu contraseña, usa la opción de recuperar '
            + 'ubicada en el formulario de inicio de sesión',
            type: 'warning',
          });
        }
        this.registerBtnDisabled = false;
        try {
          this.$gtag.event('sign_up', {
            'method': 'email',
          });
        // eslint-disable-next-line no-empty
        } catch (error) {}
      }).catch((error) => {
        this.registerBtnDisabled = false;
        this.$emit('alerta-msj', {
          content: 'Hubo un error procesando su solicitud. Intente de nuevo',
          type: 'warning',
        });
      });
    },
    newDocIdentificationInitialValue() {
      if (!this.newDoc.identificationType) return;
      switch (this.newDoc.identificationType) {
        case 'ven_cedula':
          if (!this.newDoc.identification.length) {
            this.newDoc.identification = 'V-';
          }
          break;
        default:
          this.newDoc.identification = '';
      }
      this.$refs.inputIdentification.focus();
    },
  },
};
</script>

<style lang="scss">
.step {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: $gris-muted;
  display: inline-block;
  margin: 0 1rem;
  transition: transform .25s ease, background-color .3s ease;
  vertical-align: middle;

  &.actual {
  background-color: $primary-color;
  display: inline-block;
  transform: scale(1.5);
}
}
.md-addon {
  font-weight: 500 !important;
  background-color: transparent !important;
  border: 0 !important;
}
.card-buttons {
  display: flex;
  justify-content: space-around;
  flex-flow: row-reverse wrap;
}
.overflow-x-hidden {
  overflow-x: hidden;
  overflow-y: visible;
}
</style>
