<template>
  <div id="app">
    <navBar />
    <Loader
      v-if="showLoader"
      @destroy-loader="showLoader=false"
    />
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <router-view
        v-if="$route.name == 'PerfilDeUsuario'
          || $route.name == 'evento' || $route.name == 'eventos'
          || $route.name == 'revista' || $route.name == 'articulos'
          || $route.name == 'soyMedico'"
      />
      <client-only v-else>
        <router-view />
      </client-only>
    </transition>
  </div>
</template>
<script>
import ClientOnly from 'vue-client-only';
import navBar from '@/components/layout/navigation';
import Loader from '@/components/Loader.vue';
export default {
  components: {
    navBar,
    Loader,
    ClientOnly,
  },
  data() {
    return {
      showLoader: true,
    };
  },
};
</script>
<style lang="scss">
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(1%);
    opacity: 0;
  }
</style>
