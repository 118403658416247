export default {
  // Recibe hora en formato 24hr hh:mm
  hourFormatter(time) {
    if (!time) return '';

    const [hour, minute] = time.split(':');
    const suffix = hour < 12 ? 'am' : 'pm';
    const newHour = hour > 12 ? Number(hour) - 12 : hour;

    return `${newHour}:${minute} ${suffix}`;
  },

  // Recibe día de semana y agrega acento correspondiente
  weekDayName(day) {
    const weekDays = {
      Lunes: 'Lunes',
      Martes: 'Martes',
      Miercoles: 'Miércoles',
      Jueves: 'Jueves',
      Viernes: 'Viernes',
      Sabado: 'Sábado',
      Domingo: 'Domingo',
    };

    if (!day) return '';

    return weekDays[day];
  },
};
