import 'isomorphic-fetch';
import {loadAsyncComponents} from '@akryum/vue-cli-plugin-ssr/client';
import './registerServiceWorker';

import {createApp} from './main';

createApp({
  ssr: false,
  async beforeApp({
    router,
  }) {
    await loadAsyncComponents({router});
  },

  afterApp({
    app,
    router,
  }) {
    router.onReady(() => {
      app.$mount('#app');
    });
  },
});
