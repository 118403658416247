<template>
  <mdb-modal
    ref="mdbModal"
    centered
    elegant
    scrollable
    size="lg"
    :show="showModal"
    @close="$emit('close', false);"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        Agenda una cita
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body
      class="p-0 overflow-hidden"
    >
      <iframe
        :src="`${httpEndpoint}#${doctor.profileUrl
          ? doctor.profileUrl : '|'+doctor.id}`"
        frameborder="0"
        scrolling="no"
        class="col-12 iframe-responsive"
      />
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import {mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody} from 'mdbvue';
export default {
  name: 'ModalAgendarCita',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    doctor: {
      default() {
        return {
          profileInfo: {
            acceptCallReq: null,
          },
          contactsInfo: [],
          works_at: [],
        };
      },
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      httpEndpoint: process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_RESERVA_HTTPS
  : 'http://localhost:8081',
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.iframe-responsive {
  display: block;
  padding: 0;
  height: calc(100vh - 5rem);
  margin: auto;
  width: 500px !important;

  @media (min-width: 576px) {
    width: 100% !important;
    height: 75vh;
  }
}

</style>
