<template>
  <div class="contactanos">
    <section class="main-content">
      <div class="bg-gray" />
      <div class="container text-center">
        <p class="h1-responsive font-weight-bold">
          ¿Tienes alguna pregunta?
        </p>
        <p class="lead">
          Por favor, no dudes en contactarnos directamente.
          Nuestro personal calificado está siempre dispuesto a ayudarte.
        </p>
      </div>
    </section>
    <section
      id="formularioContacto"
      class="container"
    >
      <a href="#formularioContacto">
        <div class="down-button">
          <font-awesome-icon
            :icon="['fas', 'angle-down']"
            size="lg"
            class=""
          />
        </div>
      </a>
      <h1 class="h1-responsive font-weight-bold text-center pt-4 pb-2">
        Contáctanos
      </h1>
      <p class="lead text-center">
        Escribe tu pregunta o compártenos tu opinión.
      </p>
      <!-- Formulario -->
      <form
        class="contact-form container"
        @submit.prevent="validateMsgForm"
      >
        <div class="row justify-content-center">
          <div class="col-10 col-md-4 col-lg-3">
            <mdb-input
              v-model="message.mfrom"
              type="text"
              :class="['my-2 my-md-3',
                       {'class-valid': validateValue.mfrom == 'valid'},
                       {'class-invalid': validateValue.mfrom == 'invalid'}]"
              label="Nombre Completo"
              required
              outline
            />
          </div>
          <div class="col-10 col-md-4 col-lg-3">
            <mdb-input
              v-model="message.replyemail"
              type="email"
              :class="['my-2 my-md-3',
                       {'class-valid': validateValue.replyemail == 'valid'},
                       {'class-invalid': validateValue.replyemail
                         == 'invalid'}]"
              label="Correo electrónico"
              required
              outline
            />
          </div>
          <div class="col-10 col-md-4 col-lg-3">
            <mdb-input
              v-model="message.subject"
              type="text"
              :class="['my-2 my-md-3',
                       {'class-valid': validateValue.subject == 'valid'},
                       {'class-invalid': validateValue.subject == 'invalid'}]"
              label="Asunto"
              required
              outline
            />
          </div>
          <div class="col-10 col-md-12 col-lg-9">
            <mdb-input
              v-model="message.message"
              type="textarea"
              :class="['my-2',
                       {'class-valid': validateValue.message == 'valid'},
                       {'class-invalid': validateValue.message == 'invalid'}]"
              label="Mensaje"
              :rows="3"
              required
              outline
            />
          </div>
        </div>
        <div class="row justify-content-around">
          <mdb-btn
            type="submit"
            class="col-9 col-sm-4 col-md-3 col-lg-2"
            color="success-base"
            :icon="msgBtnDisabled ? 'circle-notch' : 'envelope'"
            :icon-class="msgBtnDisabled ? 'circle-notch' : ''"
            :disabled="msgBtnDisabled"
          >
            Enviar
          </mdb-btn>
        </div>
      </form>
      <p class="text-center py-2">
        Descubre si tu duda es una de nuestras
        <a href="#FAQ">
          preguntas frecuentes
          <font-awesome-icon
            :icon="['fas', 'angle-right']"
            class="ml-1"
          />
        </a>
      </p>
    </section>
    <section
      id="FAQ"
      class="container my-5 py-3"
    >
      <div class="row align-items-center justify-content-around">
        <div class="col-12 col-sm-11 col-lg-7">
          <header class="h2-responsive text-center">
            Preguntas frecuentes
          </header>
          <p class="font-italic text-center text-muted mb-4">
            Encuentra tu respuesta entre nuestras preguntas frecuentes
          </p>
          <div class="acordeon">
            <div :class="['tarjeta', {'show': activeFaq === 1}]">
              <div
                class="encabezado"
                @click="openItem(1)"
              >
                <div class="col-auto icon">
                  <Plus />
                </div>
                <header class="col">
                  ¿Debo pagar para reservar una cita?
                </header>
              </div>
              <div class="contenido">
                <p>
                  No, al menos no a AMII. El pago se realiza directamente al
                  médico en el lugar.
                </p>
              </div>
            </div>
            <div :class="['tarjeta', {'show': activeFaq === 2}]">
              <div
                class="encabezado"
                @click="openItem(2)"
              >
                <div class="col-auto icon">
                  <Plus />
                </div>
                <header class="col">
                  ¿Cuento con asistencia técnica al ser usuario de AMII?
                </header>
              </div>
              <div class="contenido">
                <p>
                  Sí, puedes solicitar asistencia a través del formulario
                  de <a href="#formularioContacto">contacto</a> o
                  directamente por mensaje privado en nuestras redes sociales:
                </p>
                <p class="mb-0 text-center">
                  <a
                    href="https://facebook.com/app.amii"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="d-inline-block mx-2 py-2"
                  >
                    <font-awesome-icon :icon="['fab', 'facebook-square']" />
                    Facebook
                  </a>
                  <a
                    href="https://instagram.com/amii.app"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="d-inline-block mx-2 py-2"
                  >
                    <font-awesome-icon :icon="['fab', 'instagram']" />
                    Instagram
                  </a>
                  <a
                    href="https://t.me/amii_app"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="d-inline-block mx-2 py-2"
                  >
                    <font-awesome-icon :icon="['fab', 'telegram-plane']" />
                    Telegram
                  </a>
                  <a
                    href="https://wa.me/584122747740?text=¡Hola%20Soporte%20de%20AMII!,%20¿podemos%20conversar?"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="d-inline-block mx-2 py-2"
                  >
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                    WhatsApp
                  </a>
                </p>
              </div>
            </div>
            <div :class="['tarjeta', {'show': activeFaq === 3}]">
              <div
                class="encabezado"
                @click="openItem(3)"
              >
                <div class="col-auto icon">
                  <Plus />
                </div>
                <header class="col">
                  Como médico, ¿Puedo cambiar mi plan cuando quiera?
                </header>
              </div>
              <div class="contenido">
                <p>
                  Sí, puedes cambiar tu plan cuando prefieras.
                </p>
              </div>
            </div>
            <div :class="['tarjeta', {'show': activeFaq === 4}]">
              <div
                class="encabezado"
                @click="openItem(4)"
              >
                <div class="col-auto icon">
                  <Plus />
                </div>
                <header class="col">
                  ¿Existe una comunidad donde pueda preguntar y proponer ideas?
                </header>
              </div>
              <div class="contenido">
                <p>
                  Sí, siempre estamos dispuestos a escucharte. Actualmente,
                  puedes expresarte por vías privadas o a través de nuestra
                  comunidad en
                  <a
                    href="https://t.me/amii_app"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="d-inline-block mx-2"
                  >
                    <font-awesome-icon :icon="['fab', 'telegram-plane']" />
                    Telegram
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-8 col-md-7 col-lg-5">
          <img
            src="/img/contactanos/FAQs.svg"
            alt="FAQs vector decorativo"
          >
        </div>
      </div>
    </section>
    <AlertaMsj
      :alerta-msj="alertaMsj"
      @cerrar="alertaMsj.content=''"
    />
  </div>
</template>

<script>
import AlertaMsj from '@/components/AlertaMsj.vue';
import Plus from '@/components/vectores/Plus.vue';
import contactusGql from '../../graphql/contactus.gql';
import {mdbBtn, mdbInput} from 'mdbvue';
import gql from 'graphql-tag';
export default {
  name: 'Contactanos',
  components: {
    AlertaMsj,
    Plus,
    mdbBtn,
    mdbInput,
  },
  data() {
    return {
      // Mensaje de alerta
      alertaMsj: {
        content: '',
      },
      // Controla la FAQ expandida del acordeon
      activeFaq: 0,
      // Mensaje
      message: {
        mfrom: '',
        replyemail: '',
        subject: '',
        message: '',
      },
      validateValue: {
        mfrom: '',
        replyemail: '',
        subject: '',
        message: '',
      },
      // Boton de envio
      msgBtnDisabled: false,
    };
  },
  methods: {
    // Abre o cierra los faqItems
    openItem(index) {
      this.activeFaq = this.activeFaq !== index ? index : 0;
    },
    validateMsgForm() {
      this.validateValue = {
        mfrom: this.message.mfrom
          ? 'valid'
          : 'invalid',
        replyemail: this.message.replyemail
          ? 'valid'
          : 'invalid',
        subject: this.message.subject
          ? 'valid'
          : 'invalid',
        message: this.message.message
          ? 'valid'
          : 'invalid',
      };
      if (!this.message.mfrom || !this.message.replyemail
        || !this.message.subject || !this.message.message) {
        return this.alertaMsj = {
          content: 'Rellena los campos requeridos',
          type: 'warning',
        };
      }
      // Otras validaciones que pueden alterar el mensaje
      // Otro metodo de validación de ejemplo()
      const message = this.message;
      // Enviar el mensaje
      this.sendMessage(message);
    },
    sendMessage(message) {
      this.alertaMsj.content = '';
      this.msgBtnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${contactusGql}`,
        variables: {
          msg: message,
        },
      }).then((res) => {
        const msg = res.data.contactus.msg;
        switch (msg) {
          case 'success':
            this.message = {};
            this.validateValue = {
              mfrom: '',
              replyemail: '',
              subject: '',
              message: '',
            };
            this.alertaMsj = {
              content: 'Mensaje enviado',
              type: 'success',
            };
            break;
          case 'failed':
            this.alertaMsj = {
              content: 'Mensaje no enviado. Intenta de nuevo',
              type: 'danger',
            };
            break;
          default:
            this.alertaMsj = {
              content: 'Hubo algún error. Intenta más tarde.',
              type: 'danger',
            };
            break;
        }
        this.msgBtnDisabled = false;
      }).catch((error) => {
        this.msgBtnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo algún error. Intenta más tarde.',
          type: 'danger',
        };
      });
    },
  },
};
</script>
<style lang="scss">
  .contactanos {
    .main-content {
      align-items: center;
      background-image: url('../../../public/img/doctor_paciente.jpg');
      background-position: center center;
      background-size: cover;
      display: flex;
      height: calc(100vh - 94px);
      @media (min-width: 576px) {
        height: calc(100vh - 96px);
      }
      @media (min-width: 768px) {
        height: calc(100vh - 102px);
      }
      @media (min-width: 1200px) {
        height: calc(100vh - 110px);
      }
      .bg-gray {
        background-color: rgba(66, 66, 66, 0.4);
        height: calc(100vh - 94px);
        position: absolute;
        width: 100%;
        @media (min-width: 576px) {
          height: calc(100vh - 96px);
        }
        @media (min-width: 768px) {
          height: calc(100vh - 102px);
        }
        @media (min-width: 1200px) {
          height: calc(100vh - 110px);
        }
      }
      .container.text-center {
        color: $white;
        position: relative;
      }
    }
    .down-button {
      align-items: center;
      background-image: linear-gradient(to bottom right,
        $primary-color-dark, $primary-color);
      border-radius: 100%;
      box-shadow: 2px 2px 8px $grey-base;
      color: $white;
      display: flex;
      height: 46px;
      left: calc(50% - 21px);
      margin-bottom: -1rem;
      padding: .5rem 1rem;
      position: relative;
      transform: translateY(-21px);
      width: 46px;
    }
  }

  .acordeon {
    overflow-y: hidden;
  }
  .acordeon .tarjeta {
    margin-bottom: 1rem;
    overflow: hidden;
    .encabezado {
      align-items: center;
      background-color: #f0f0f0;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      padding: 1rem .8rem;
      position: relative;
      transition: background-color .25s ease;
      z-index: 1;

      .icon {
        height: 24px;
        padding: 0;
        width: 20px;

        svg {
          fill: #495057;
          margin-top: -2px;
          transition-property: fill, transform;
          transition-timing-function: ease;
          transition-duration: .25s;
        }
      }

      header {
        color: #495057;
        font-weight: 700;
        line-height: 1.3;
        padding: 0 .6rem;
        text-transform: uppercase;
        transition: color .25s ease;
        user-select: none;
      }
    }

    .contenido {
      color: #495057;
      height: 0;
      opacity: 0;
      padding: 0 1.3rem;
      transition-property: height, opacity;
      transition-timing-function: ease;
      transition-duration: .20s;
    }
  }
  .acordeon .tarjeta.show {
    .encabezado {
      background-color: $primary-color;

      .icon svg {
        fill: $white;
        transform: rotate(-45deg);
      }

      header {color: $white;}
    }

    .contenido {
      height: inherit;
      opacity: 1;
      padding: .5rem 1.3rem;
    }
  }
</style>
