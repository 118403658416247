import Vue from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import {createRouter} from './router';
import './registerServiceWorker';

import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbvue/build/scss/mdb.scss';
// import './assets/sass/app.scss';
import 'mdbvue/lib/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import './fa-icons';

import {createProvider} from './vue-apollo';
import VueLazyLoad from 'vue-lazyload';
import VueImg from 'v-img';
import 'regenerator-runtime';
import Filters from './filters';

Vue.mixin({
  filters: Filters,
});

Vue.use(VueImg, {altAsTitle: true});

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(VueLazyLoad);
Vue.use(VueGtag, {
  config: {id: 'G-V8VTFCJQBV'},
  bootstrap: process.env.NODE_ENV === 'production',
}, createRouter());

Vue.config.productionTip = false;

/**
 * Montar aplicación website
 * @param {Object} options Options
 * @return {createApp} App
 */
export async function createApp({
  beforeApp = () => {},
  afterApp = () => {},
} = {}) {
  const router = createRouter();

  const apolloProvider = createProvider({
    ssr: process.server,
  });

  await beforeApp({
    router,

    apolloProvider,
  });

  const app = new Vue({
    router,
    apolloProvider,
    render: (h) => h(App),
  });

  const result = {
    app,
    router,

    apolloProvider,
  };

  await afterApp(result);

  return result;
}
