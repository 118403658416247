<template>
  <div
    :class="['md-form', className,
             {'class-valid': validateValue.identificationType == 'valid'},
             {'class-invalid': validateValue.identificationType == 'invalid'}]"
  >
    <i
      v-if="icon"
      class="fas fa-user prefix"
    />
    <select
      id="identification-select"
      ref="sit"
      :value="value"
      :class="['custom-select', { 'with-icon': icon }]"
      @change="changed($event.target.value)"
    >
      <option
        v-for="(type, i) in identificationTypes"
        :key="i"
        :value="type.value"
        :class="[{ 'text-black': icon },
                 { 'd-none': type.value == '' }]"
      >
        {{ type.name }}
      </option>
    </select>
    <label
      for="identification-select"
      :class="[value.length ? 'label-active' : 'label-inactive']"
    >
      Tipo de identificación
    </label>
  </div>
</template>
<script>
export const SelectIdentificationType = {
  name: 'SelectIdentificationType',
  props: {
    // Clase del div principal
    className: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    validateValue: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      isActiveList: false,
      identificationType: {
        name: 'Seleccione',
        value: '',
        regExp: '',
        mensaje: 'Selecciona un tipo de identificación',
      },
      identificationTypes: [
        {
          name: 'Seleccione',
          value: '',
          regExp: '',
          mensaje: 'Selecciona un tipo de identificación',
        },
        {
          name: 'Cédula (Venezolana)',
          value: 'ven_cedula',
          regExp: '^[VE]-[0-9]{6,9}$',
          mensaje: 'La cédula comienza por V o E seguida de guión y números',
        },
        {
          name: 'Cédula (Uruguay)',
          value: 'ury_cedula',
          regExp: '^[0-9]{6,9}-[0-9]{1}$',
          mensaje: 'La cédula es numérica seguida de guión y un número',
        },
        {
          name: 'Cédula (Ecuador)',
          value: 'ecu_cedula',
          regExp: '^[0-9]{10}$',
          mensaje: 'La cédula es numérica de 10 dígitos',
        },
        {
          name: 'DNI (Argentino)',
          value: 'arg_dni',
          regExp: '^L?[0-9]{6,10}$',
          mensaje: 'El DNI debe ser numérico, opcionalmente puede comenzar'
          + ' por L',
        },
        {
          name: 'RUT (Chileno)',
          value: 'chl_rut',
          regExp: '^[0-9]{6,10}-[A-Za-z0-9]$',
          mensaje: 'Debe ser numérico seguido de un guión y un número o letra',
        },
        {
          name: 'SSN (Norte americano)',
          value: 'usa_ssn',
          regExp: '^[0-9]{3}-[0-9]{2}-[0-9]{3}$',
          mensaje: 'Debe estar compuesto por números separados por guión',
        },
        {
          name: 'Pasaporte',
          value: 'pasaporte',
          regExp: '^[A-Za-z0-9]{6,10}$',
          mensaje: 'El pasaporte debe ser alfanumérico de 6 a 10 caracteres',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.sit.focus();
    },
    changed(value) {
      this.$emit('input', value);
      this.identificationType = this.identificationTypes.filter((i) =>
        i.value == value)[0];
      this.$emit('save', JSON.parse(JSON.stringify(this.identificationType)));
    },
  },
};

const identificationTypes = {};
SelectIdentificationType.data().identificationTypes.forEach((e) => {
  identificationTypes[e.value] = e.name;
});
export {identificationTypes};
export default SelectIdentificationType;
</script>
<style lang="scss">
  .text-black {
    color: black !important;
  }
</style>
