/**
 * Esquemas de validación de entradas para formularios.
 * Ordenadas por categorías y alfabéticamente.
 * @returns {schema}
 */
import {object, string, date, array as arraySchema, boolean} from 'yup';

/* Categoría: Entidades */
const personSchema = object().shape({
  fname: string().required().min(2).max(20).trim(),
  mname: string().max(20).trim().ensure(),
  lname: string().required().min(2).max(20).trim(),
  llname: string().max(20).trim().ensure(),
  identification: string().required(),
  identificationType: string().required(),
  gender: string().required(),
  born: date('Y-m-d').required('Coloca la fecha de nacimiento')
      .max(todayDate(), 'Nacimiento debe ser antes de hoy.'),
});

const doctorSchema = object(personSchema).shape({
  matricula: string().required(function() {
    throw new Error('Indicar la matrícula.');
  }).min(2).max(20).trim(),
});


const appointmentSchema = object().shape({
  date: date('Y-m-d').required('Coloca la fecha de la cita')
      .min(todayDate(), 'La fecha de la cita debe ser para hoy '
        +'ó días siguientes'),
});

const emailSchema = object().shape({
  email: string().required().email().trim(),
  primary: boolean(),
  status: string().trim(),
});

const diagnosisSchema = object().shape({
  conclusion: string().trim(),
  disease: object().shape({
    name: string().trim(),
  }),
});

const receipSchema = object().shape({
  medicine: object().shape({
    name: string().trim(),
    dose: string().trim(),
    presentation: string().trim(),
  }),
  custom: string().trim(),
  unit: string().trim(),
});

/* Categoría: Formatos */
/**
 * @return {Date} fecha actual
 */
function todayDate() {
  return (new Date()).toISOString().split('T')[0];
}

/**
 * Calcular edad
 * @param {String} born Fecha en formato YYY-mm-dd
 * @return {Int} Edad
 */
function calculateAge(born) {
  // born = new Date(born.year, born.month, born.day);
  const ageDifMs = Date.now() - born.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
/* Categoría rangos */
/**
 *
 * @param {String} start Hora en formato HH:mm
 * @param {String} end Hora en formato HH:mm
 * @return {Boolean} True si es valido, false si no lo es
 */
function validHoursRange(start, end) {
  start = start.split(':');
  end = end.split(':');
  return (start[0] > end[0] || (start[0] == end[0] && start[1] >= end[1]))
    ? false : true;
}

/**
 * Debouncer para que una funcion se ejecute al cabo de b tiempo
 * @param {Function} a Funcion a ser llamada una vez se cumpla el timeout
 * @param {Int} b Tiempo a esperar por el timeout
 * @param {Boolean} c Indica si es inmediato o no
 * @return {Function} Funcion a ser ejecutada
 */
const debounce =
  (a, b=250, c)=>(...d)=>clearTimeout(c, c=setTimeout(()=>a(...d), b));

/**
 * Capitaliza la primera letra de una cadena string
 * @param {String} string Cadena de texto a capitalizar
 * @return {String}
 */
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Capitaliza la primera letra de cada palabra de una cadena string
 * @param {String} string Cadena de texto a capitalizar
 * @return {String}
 */
function capitalizeAll(string) {
  if (string) {
    const textotoLowerCase = string.toLowerCase();
    const textoCapitalize =
      textotoLowerCase.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, ' ');
    string = textoSinEspacios;
    return string;
  }
}

/**
 * Devuelve una fecha en formato legible es_VE
 * @param {String} string Fecha en cadena de texto
 * @return {String}
 */
function fechaEsVe(string) {
  if (!string) return '';
  string = new Date(string);
  return string.toLocaleDateString('es-VE', {
    day: '2-digit', month: '2-digit', year: 'numeric',
    hour: 'numeric', minute: 'numeric',
  });
}
/**
 * Devuelve un true si cumple con la validacion, caso contrario false
 * @param {String} identificationType Objeto con Expresion Regular a comparar
 * @param {String} identification Identificacion del Usuario
 * @return {Boolean}
 */
function validateIdentification(identificationType, identification) {
  const regExp = new RegExp(identificationType.regExp);
  return regExp.test(identification);
}

export {
  arraySchema,
  calculateAge,
  capitalize,
  capitalizeAll,
  fechaEsVe,
  debounce,
  doctorSchema,
  emailSchema,
  personSchema,
  appointmentSchema,
  todayDate,
  validHoursRange,
  diagnosisSchema,
  receipSchema,
  validateIdentification,
};
