<template>
  <div
    :class="[className, 'md-form',
             { 'md-outline outline-input': outline },
             'ul-data']"
  >
    <!-- v-model="value" -->
    <input
      :id="label + identificador + '-label'"
      ref="input"
      :value="value"
      :disabled="disabled"
      :required="required"
      class="form-control mb-0"
      :type="type"
      autocomplete="off"
      list="custom-datalist"
      @input="isActiveList = true;$emit('input', $event.target.value)"
      @focus="isActiveList = true"
      @click="isActiveList = true"
      @blur="hideList"
    >
    <label
      :for="label + identificador + '-label'"
      :class="value && value.toString().length
        ? 'label-active' : 'label-inactive'"
    >
      {{ label }}
    </label>
    <datalist
      id="custom-datalist"
    >
      <option
        v-for="(option, i) in list"
        :key="i"
        :value="option.value"
        @click="selected(option)"
      >
        {{ option.name }}
      </option>
    </datalist>
  </div>
</template>
<script>
export default {
  name: 'CustomDatalist',
  props: {
    className: { // Clase del div principal
      type: [String, Array],
      required: true,
    },
    identificador: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // value: '',
      isActiveList: false,
    };
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
    },
    selected(option) {
      console.log(option);
      this.isActiveList = false;
      this.$emit('save', option);
    },
    hideList() {
      this.isActiveList = false;
    },
  },
};
</script>
<style lang="scss">
  .md-outline.outline-data .label-active {
    background: $white;
    font-weight: 500;
    padding: 0 5px;
  }
</style>
