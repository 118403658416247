import Vue from 'vue';
import VueApollo/* , {ApolloProvider} */ from 'vue-apollo';
import {createApolloClient/* , restartWebsockets */}
from 'vue-cli-plugin-apollo/graphql-client';
import {setContext} from 'apollo-link-context';

const originLink = setContext(async (_, {headers}) => {
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      origin: 'https://amii.app',
    },
  };
});
// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const envEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql';
const httpEndpoint = process.env.NODE_ENV === 'production'
  ? envEndpoint
  : 'http://localhost:4000/graphql';
// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT
  || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  inMemoryCacheOptions: {
    freezeResults: false,
  },
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  // websocketsOnly: false,
  // Is being rendered on the server?
  ssr: true,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: originLink,

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};
// Call this in the Vue app file
/**
 * Crear proveedor de Apollo
 * @param {Object} options Options
 * @return {ApolloProvider} Apollo
 */
export function createProvider(options = {}) {
  // Create apollo client
  const {apolloClient/* , wsClient */} = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  // apolloClient.wsClient = wsClient
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px;'
      +' border-radius: 3px; font-weight: bold;', error.message);
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
/**
 * onLogin al hacer login
 * @param {apolloClient} apolloClient apollo Client
 * @param {String} token token de sesión
 */
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out

/**
 * onLogin al hacer login
 * @param {apolloClient} apolloClient apollo Client
 * @param {String} token token de sesión
 */
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
