<template>
  <div @click="modalVerDetalles(doc.id);">
    <section class="card cursor-pointer p-2">
      <div class="d-flex flex-wrap align-items-center">
        <div
          v-if="doc.profileInfo
            && doc.profileInfo.image
            && doc.profileInfo.image.url"
          class="col-12 col-sm-4 py-1 p-md-0"
        >
          <img
            :src="`${imgPath}${doc.profileInfo.image.url}`"
            alt=""
            class="w-100 rounded-pill"
          >
        </div>
        <div class="col-12 col-sm">
          <header class="doctor-card">
            {{ doc.prename }}
            {{ doc.fname }}
            {{ doc.mname }}
            {{ doc.lname }}
            {{ doc.llname }}
            {{ doc.posname }}
          </header>
          <p class="degrees">
            {{ searchTags() }}
          </p>
        </div>
      </div>
      <ul class="offices">
        <li
          v-for="work in doc.works_at"
          :key="work.id"
        >
          {{ work.in.location.name }}
        </li>
      </ul>
      <slot name="botones" />
    </section>
  </div>
</template>

<script>
import {capitalizeAll} from '@/utils/validations.js';
const imgPath = process.env.NODE_ENV === 'production'
              ? process.env.VUE_APP_MULTIMEDIA_HTTPS
              : process.env.VUE_APP_MULTIMEDIA_HTTP;
export default {
  name: 'TarjetaResultadoPersona',
  components: {
  },
  props: {
    doc: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      imgPath: imgPath+'dir/',
    };
  },
  methods: {
    modalVerDetalles(docId) {
      this.$emit('modal-ver-detalles', docId);
    },
    searchTags() {
      let tags = '';
      if (this.doc.profileInfo
        && this.doc.profileInfo.searchTags
        && this.doc.profileInfo.searchTags.length
        && this.doc.profileInfo.searchTags[0].name) {
        this.doc.profileInfo.searchTags.map((tag) => {
          if (tags.length > 0) tags += ', ';
          tags += capitalizeAll(tag.name);
        });
      }
      return tags;
    },
  },
};
</script>

<style lang="scss">
  header.doctor-card {
    font-weight: 600;
  }
  p.degrees {
    font-size: .95rem;
    margin-bottom: 0;
  }
  ul.offices {
    font-size: .90rem;
    list-style: none;
    margin-bottom: .5rem;

    li {
      display: inline;
      &::before {
        content: ', ';
        margin-left: -2px;
      }
      &:first-child::before {
        content: '';
        margin-left: 0;
      }
      &:last-child::after {
        content: '.';
        margin-left: -3px;
      }
    }
  }
</style>
