<template>
  <mdb-navbar
    color="bg-primary-dark-base"
    dark
    expand="lg"
    position="top"
    scrolling
  >
    <mdb-navbar-brand to="/">
      <span class="icon-container mr-1">
        <svg
          id="svg13"
          xmlns:dc="http://purl.org/dc/elements/1.1/"
          xmlns:cc="http://creativecommons.org/ns#"
          xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
          xmlns:svg="http://www.w3.org/2000/svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
          xml:space="preserve"
          width="250mm"
          height="190mm"
          viewBox="0 0 250.00001 152"
          version="1.1"
          sodipodi:docname="logo_amii_250x190mm.svg"
          inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
        >
          <metadata id="metadata17">
            <rdf:RDF>
              <cc:Work rdf:about="">
                <dc:format>image/svg+xml</dc:format>
                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
              </cc:Work>
            </rdf:RDF>
          </metadata>
          <sodipodi:namedview
            id="namedview15"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="1920"
            inkscape:window-height="1043"
            showgrid="false"
            inkscape:zoom="0.55273973"
            inkscape:cx="270.27005"
            inkscape:cy="270.89198"
            inkscape:window-x="0"
            inkscape:window-y="0"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg13"
          />
          <g
            id="g11"
            transform="translate(-14.078742,-36.541218)"
          >
            <path
              id="nube"
              class="fil0 str0 str3-color str3-width6"
              d="M 80.3645,51.4333 C 84.3482,19.1951 36.4712,12.0139
                27.2943,46.8105 c -1.4216,33.8307 46.3886,38.2723
                53.0702,4.6228 -0.2829,18.3891 13.7366,22.1167
                21.2446,8.4157 2.5022,-4.5667 2.8474,-8.8107
                3.9074,-13.7016 6.5131,-30.0453 41.1244,-25.5072
                40.2493,5.4702 l 0.009,16.4111 -0.009,-16.4111 C
                147.4692,16.0364 202.508,17.7243 200.26,51.4333 l
                0.2813,16.4715 -0.2813,-16.4715 c 1.501,-33.2526
                49.5022,-32.5328 54.7478,-3.8861 0.9657,5.2737
                1.5125,12.2994 1.6598,19.8872 0.2813,14.4863
                0.2448,30.639 -1.1508,41.1661 -5.8564,44.1732
                -76.9434,50.8064 -79.6948,0.0788 1.2135,53.8592
                -57.7041,51.9733 -64.1966,17.6293 -0.9645,-5.1027
                -1.4745,-11.006 -1.0196,-17.6815 C 94.6341,180.8337
                0.3668,136.7911 25.581,53.5973 l 1.7133,-6.7868"
              inkscape:connector-curvature="0"
              style="stroke-linecap:round;stroke-linejoin:round"
            />
            <rect
              id="punto2"
              class="str1 str3-color fil2"
              x="173.255"
              y="94.910301"
              width="4.9043598"
              height="4.9675398"
              rx="0.6002"
              ry="0.6002"
              style="fill:#fff;stroke:#fff;stroke-width:6.64440012"
            />
            <rect
              id="punto1"
              class="str1 str3-color fil2"
              x="109.55"
              y="94.499298"
              width="4.9043598"
              height="4.9675398"
              rx="0.6002"
              ry="0.6002"
              style="fill:#fff;stroke:#fff;stroke-width:6.64440012"
            />
            <path
              id="esteto"
              class="fil0 str1 str3-color"
              d="m 142.348,151.551 -0.0997,36.9673 c 0.0327,15.2
                18.7399,18.5665 29.262,6.2739 l 7.0332,-7.5885"
              inkscape:connector-curvature="0"
              sodipodi:nodetypes="cccc"
            />
            <circle
              id="scopio"
              style="stroke-linecap:butt;stroke-linejoin:round;"
              class="str1 str3-color fil0"
              cx="-0.74246454"
              cy="-257.70447"
              r="9.7580128"
              transform="rotate(135)"
            />
          </g>
        </svg>
      </span>
      <h1 class="navbar-brand m-0 p-0">
        AMII
      </h1>
    </mdb-navbar-brand>
    <mdb-navbar-toggler>
      <mdb-navbar-nav
        right
      >
        <mdb-nav-item
          to="/"
          exact
        >
          <font-awesome-icon
            :icon="['fas', 'home']"
            size="lg"
            class="d-inline-block d-lg-none w-27px"
          />
          Home
        </mdb-nav-item>
        <mdb-nav-item
          to="/nosotros"
          exact
        >
          <font-awesome-icon
            :icon="['fas', 'users']"
            size="lg"
            class="d-inline-block d-lg-none w-27px"
          />
          Nosotros
        </mdb-nav-item>
        <mdb-nav-item
          to="/soy-medico"
          :active="activeSoyMedico"
          class="text-nowrap"
        >
          <font-awesome-icon
            :icon="['fas', 'user-md']"
            size="lg"
            class="d-inline-block d-lg-none w-27px"
          />
          Soy médico
        </mdb-nav-item>
        <mdb-nav-item
          to="/precios"
          exact
        >
          <font-awesome-icon
            :icon="['fas', 'dollar-sign']"
            size="lg"
            class="d-inline-block d-lg-none w-27px"
          />
          Precios
        </mdb-nav-item>
        <mdb-nav-item
          to="/revista"
          :active="activeRevista"
          class="d-none"
        >
          <font-awesome-icon
            :icon="['fas', 'newspaper']"
            size="lg"
            class="d-inline-block d-lg-none w-27px"
          />
          Revista
        </mdb-nav-item>
        <mdb-nav-item
          to="/eventos"
          :actve="activeEventos"
          class="d-none"
        >
          <font-awesome-icon
            :icon="['fas', 'bullhorn']"
            size="lg"
            class="d-inline-block d-lg-none w-27px"
          />
          Eventos
        </mdb-nav-item>
        <mdb-nav-item
          to="/contactanos"
          exact
        >
          <font-awesome-icon
            :icon="['fas', 'phone-square-alt']"
            size="lg"
            class="d-inline-block d-lg-none w-27px"
          />
          Contáctanos
        </mdb-nav-item>
        <mdb-nav-item
          new-tab
          href="https://facebook.com/app.amii"
          title="Facebook"
        >
          <font-awesome-icon
            :icon="['fab', 'facebook-square']"
            size="lg"
            class="w-27px"
          />
          <span class="ml-1 d-inline-block d-lg-none">Facebook</span>
        </mdb-nav-item>
        <mdb-nav-item
          new-tab
          href="https://instagram.com/amii.app"
          title="Instagram"
        >
          <font-awesome-icon
            :icon="['fab', 'instagram']"
            size="lg"
            class="w-27px"
          />
          <span class="ml-1 d-inline-block d-lg-none">Instagram</span>
        </mdb-nav-item>
        <mdb-nav-item
          new-tab
          href="https://t.me/amii_app"
          title="Telegram"
        >
          <font-awesome-icon
            :icon="['fab', 'telegram-plane']"
            size="lg"
            class="w-27px"
          />
          <span class="ml-1 d-inline-block d-lg-none">Telegram</span>
        </mdb-nav-item>
        <mdb-nav-item
          new-tab
          href="https://twitter.com/amii_app"
          title="Twitter"
        >
          <font-awesome-icon
            :icon="['fab', 'twitter-square']"
            size="lg"
            class="w-27px"
          />
          <span class="ml-1 d-inline-block d-lg-none">Twitter</span>
        </mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>
<script>
import {mdbNavbar, mdbNavbarBrand, mdbNavbarToggler,
  mdbNavbarNav, mdbNavItem} from 'mdbvue';

export default {
  name: 'Navigation',
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
  },
  data() {
    return {
    };
  },
  computed: {
    activeEventos() {
      return this.$route.name == 'eventos'
      || this.$route.name == 'evento'
      ? true
      : false;
    },
    activeRevista() {
      return this.$route.name == 'revista'
      || this.$route.name == 'articulo'
      ? true
      : false;
    },
    activeSoyMedico() {
      return this.$route.name == 'soyMedico'
      ? true
      : false;
    },
  },
};
</script>
<style lang="scss">
  nav ul.navbar-nav li.nav-item a.active.nav-link.navbar-link {
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: bold;
  }
  .w-27px { width: 27px !important; }
  /* Estilos del svg */
  .navbar-brand .icon-container{
    display: inline-block;
    height: 40px;
    margin-bottom: -20px;
    margin-top: -20px;

    svg#svg13 {
      height: 100%;
      width: auto;
    }
  }
  .fil0 {fill:none}
  .fil2 {fill: #ffffff;}
  .str1 {stroke:#0D47A1;stroke-width:6;}
  .str0 {
    stroke:#0D47A1;
    stroke-width:9;
    stroke-linecap:round;
    stroke-linejoin:round;
  }
  .str3-color {stroke:#ffffff !important;}
  .str3-width6 {stroke-width:6 !important;}
  /* Fin estilos del svg */
</style>
