<template>
  <button
    ref="btnLoader"
    :type="btnType"
    :class="['btn', `btn-${btnColor}`, btnClasses]"
    :disabled="btnDisabled"
    @click.stop="atClick"
  >
    <font-awesome-icon
      v-if="!iconRight"
      :icon="['fas', btnDisabled ? 'circle-notch' : btnIcon]"
      size="lg"
      :class="['mr-1', {'fa-spin': btnDisabled}]"
    />
    {{ btnTitle }}
    <font-awesome-icon
      v-if="iconRight"
      :icon="['fas', btnDisabled ? 'circle-notch' : btnIcon]"
      size="lg"
      :class="['ml-1', {'fa-spin': btnDisabled}]"
    />
  </button>
</template>
<script>
export default {
  name: 'BtnLoader',
  components: {},
  props: {
    btnColor: {
      type: String,
      default: 'success-base',
    },
    btnClasses: {
      type: [String, Array],
      default: '',
    },
    btnIcon: {
      type: String,
      default: 'check',
    },
    btnDisabled: {
      type: Boolean,
      required: true,
    },
    btnTitle: {
      type: String,
      required: true,
    },
    btnType: {
      type: String,
      default: 'button',
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    atClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>
<style lang="scss">

</style>
